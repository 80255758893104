import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
    name: 'dateFormat'
})
@Injectable({
    providedIn: 'root'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
    transform(date: string | Date, format: string): any {
        if (date) {
            return super.transform(date, format);
        }
        else {
            return 'N/A';
        }
    }
}