import { ErrorHandler, Injectable, Injector, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApolloModule } from 'apollo-angular';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavComponent } from './shared/sidenav/sidenav.component';
import { HeaderComponent } from './shared/header/header.component';
import { LogoutComponent } from './logout/logout.component';
import { TermsComponent } from './terms/terms.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { SessionComponent } from './session/session.component';
import { GraphQLModule } from './graphql.module';
import { ContactComponent } from './contact/contact.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentVariablesService } from './services/environment-variables.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { HighchartsChartModule } from 'highcharts-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './shared/loading/loading.component';
import { GlobalErrorHandler } from './services/global-error-handler';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NotificationComponent } from './notification/notification.component';
import { Okta } from './shared/okta.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NavigationEnd, Router } from '@angular/router'
import { OktaAuth } from '@okta/okta-auth-js';
import { InvestorDashboardComponent } from './investor-dashboard/investor-dashboard.component';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SortByPipe } from './pipes/sortBy.pipe';
import { SeriesDashboardComponent } from './series-dashboard/series-dashboard.component';
import { AutoAdjustingDropdownComponent } from './auto-adjusting-dropdown/auto-adjusting-dropdown.component';
import { OktaTokenAuthInterceptor } from './core/http/okta-token-auth-interceptor';
import { MatTabsModule } from '@angular/material/tabs';
import { CommitmentValueWidgetComponent } from './series-dashboard/commitment-value-widget/commitment-value-widget.component';
import { RemainingValueWidgetComponent } from './series-dashboard/remaining-value-widget/remaining-value-widget.component';
import { KeyPerformanceWidgetComponent } from './series-dashboard/key-performance-widget/key-performance-widget.component';
import { BaseWidgetComponent } from './shared/base-widget/base-widget.component';
import { GcmChartComponent } from './shared/gcm-chart/gcm-chart.component';
import { SeriesQRollTableComponent } from './series-dashboard/series-qroll-table/series-qroll-table.component';
import { SeriesHoldingsTableComponent } from './series-dashboard/series-holdings-table/series-holdings-table.component';
import { IrrValueFormatPipe } from './pipes/irr-value-format.pipe'
import { TvpiValueFormatPipe } from './pipes/tvpi-value-format.pipe'
import { SeriesDashboardSummaryComponent } from './series-dashboard/series-dashboard-summary/series-dashboard-summary.component';
import { HoldingsDashboardComponent } from './holdings-dashboard/holdings-dashboard.component';
import { HoldingDashboardSummaryComponent } from './holdings-dashboard/holding-dashboard-summary/holding-dashboard-summary.component';
import { NgbModalContentComponent } from './shared/ngb-modal-content/ngb-modal-content.component';
import { SectorsWidgetComponent } from './holdings-dashboard/sectors-widget/sectors-widget.component';
import { GeographyWidgetComponent } from './holdings-dashboard/geography-widget/geography-widget.component';
import { AssetsDetailTableComponent } from './holdings-dashboard/assets-detail-table/assets-detail-table.component';
import { BaseGeographyWidgetComponent } from './shared/base-geography-widget/base-geography-widget.component';
import { LargestPositionWidgetComponent } from './holdings-dashboard/largest-position-widget/largest-position-widget.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { HeaderSidenavLayoutComponent } from './shared/header-sidenav-layout/header-sidenav-layout.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { AssetDashboardComponent } from './asset-dashboard/asset-dashboard.component';
import { AssetSummaryComponent } from './asset-dashboard/asset-summary/asset-summary.component';
import { FinancialSummaryComponent } from './asset-dashboard/financial-summary/financial-summary.component';
import { MapWidgetComponent } from './shared/map-widget/map-widget.component';
import { StandardStringFormatPipe } from './pipes/standard-string-format.pipe';
import { ImageCarouselComponent } from './shared/image-carousel/image-carousel.component';
import { AssetClassPipe } from './pipes/asset-class.pipe';
import { ExpandedViewSliderComponent } from './shared/expanded-view-slider/expanded-view-slider.component';
import { InvestorSummaryComponent } from './investor-dashboard/investor-summary/investor-summary.component';
import { CumulativeChartComponent } from './investor-dashboard/portfolio-summary/cumulative-chart/cumulative-chart.component';
import { PortfolioSummaryComponent } from './investor-dashboard/portfolio-summary/portfolio-summary.component';
import { InfoIconComponent } from './shared/info-icon/info-icon.component';
import { ErrorCardComponent } from './shared/error-card/error-card.component';
import { NoAccessErrorComponent } from './shared/no-access-error/no-access-error.component';
import { AuthErrorComponent } from './auth-error/auth-error.component'
import { LogoutButtonComponent } from './shared/logout-button/logout-button.component';
import { InvestorDropdownComponent } from './shared/investor-dropdown/investor-dropdown.component'
import { MatMenuModule } from '@angular/material/menu';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RaygunV2 } from 'raygun4js';
import { LoggingService } from './services/logging.service';
import { InfoTextBoxComponent } from './shared/info-text-box/info-text-box.component';
import { DocumentReviewComponent } from './document-review/document-review.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatInputModule } from '@angular/material/input';
declare const rg4js: RaygunV2

export function setupOktaConfig(okta: Okta) {
  return {
    oktaAuth: okta.getOktaAuth(),
    onAuthRequired: onAuthRequired
  };
}
function onAuthRequired(oktaAuth: OktaAuth, injector: Injector) {
  // Use injector to access any service available within your application
  const router = injector.get(Router);
  let route = router.getCurrentNavigation()?.finalUrl.toString();

  // Redirect the user to your custom login page
  router.navigate(["/login"], { queryParams: { returnUrl: route } });
}

@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  constructor(private loggingService: LoggingService){
  }
  handleError(e: any) {
    this.loggingService.log("error", e);
    rg4js('send', {
      error: e,
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    LoginComponent,
    LogoutComponent,
    TermsComponent,
    NotfoundComponent,
    SessionComponent,
    ContactComponent,
    LoadingComponent,
    NotificationComponent,
    SafeHtmlPipe,
    InvestorDashboardComponent,
    SeriesDashboardComponent,
    CurrencyFormatPipe,
    SortByPipe,
    AutoAdjustingDropdownComponent,
    CommitmentValueWidgetComponent,
    RemainingValueWidgetComponent,
    KeyPerformanceWidgetComponent,
    BaseWidgetComponent,
    GcmChartComponent,
    SeriesQRollTableComponent,
    SeriesHoldingsTableComponent,
    IrrValueFormatPipe,
    TvpiValueFormatPipe,
    SeriesDashboardSummaryComponent,
    HoldingsDashboardComponent,
    HoldingDashboardSummaryComponent,
    NgbModalContentComponent,
    SectorsWidgetComponent,
    GeographyWidgetComponent,
    AssetsDetailTableComponent,
    BaseGeographyWidgetComponent,
    LargestPositionWidgetComponent,
    HeaderSidenavLayoutComponent,
    AssetDashboardComponent,
    AssetSummaryComponent,
    DateFormatPipe,
    StandardStringFormatPipe,
    ImageCarouselComponent,
    AssetClassPipe,
    FinancialSummaryComponent,
    MapWidgetComponent,
    StandardStringFormatPipe,
    ImageCarouselComponent,
    ExpandedViewSliderComponent,
    InvestorSummaryComponent,
    CumulativeChartComponent,
    PortfolioSummaryComponent,
    InfoIconComponent,
    ErrorCardComponent,
    NoAccessErrorComponent,
    AuthErrorComponent,
    LogoutButtonComponent,
    InvestorDropdownComponent,
    CurrencySymbolPipe,
    InfoTextBoxComponent,
    DocumentReviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OktaAuthModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MatSliderModule,
    GraphQLModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    ApolloModule,
    GoogleMapsModule,
    NgIdleKeepaliveModule.forRoot(),
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    HighchartsChartModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    TourMatMenuModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    MatChipsModule
  ],

  providers: [
    CookieService,
    EnvironmentVariablesService,
    AssetClassPipe,
    {
      provide: OKTA_CONFIG, useFactory: setupOktaConfig,
      deps: [Okta]
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: OktaTokenAuthInterceptor, multi: true, deps: [EnvironmentVariablesService, Okta] },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}