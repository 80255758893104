import { LoadingService } from './../services/loading.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { ApiService } from '../core/http/api.service';
import { Document } from '../models/classes/document.class';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PagedResponse } from '../models/classes/paged-response.class';
import { DocumentQuery } from '../models/classes/document-query.class';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { DateAdapter } from '@angular/material/core';
import { DateCalculationsService } from '../services/date-calculations.service';

@Component({
  selector: 'app-document-review',
  templateUrl: './document-review.component.html',
  styleUrls: ['./document-review.component.scss']
})
export class DocumentReviewComponent implements OnInit, AfterViewInit, OnDestroy {
  documents = new PagedResponse();
  displayedColumns: string[] = ['reportingName', 'status', 'error', 'reportingPeriod', 'createdOn'];
  dataSource: MatTableDataSource<Document>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchForm: FormGroup;
  dateRangeList: string[] = ["Last 7 days", "Last 30 days", "This Month", "This Quarter", "This Year"];
  @ViewChild('statusSelect') statusSelect: MatSelect;
  @ViewChild('dateSelect') dateSelect: MatSelect;
  statusFilter: string[] = [];
  statusOptions: any[] = [
    {
      value: "Generated",
      display: "Generated"
    },
    {   
      value: "GeneratedWithMissingData",
      display: "Generated With Missing Data"
    },
    {
      value: "GeneratedWithErrors",
      display: "Generated With Errors"
    },
    {   
      value: "FailedToGenerate",
      display: "Failed To Generate"
    }
  ];
  dateFilters: string = '';
  isOpenDatePicker = false;
  startDate: any;
  endDate: any;
  selectedDate: string = '';
  @ViewChild('picker') picker: any;
  allFilters: string[] = [];
  customDateRange: string = '';
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  pageSizes: number[] = [10, 25, 50, 75, 100];
  page: number = 0;
  pageSize: number = 10;
  matSortEvent: Sort;
  @ViewChild('investorSelect') investorSelect: MatSelect;
  investorFilters: any[] = [];
  @ViewChild('portfolioSelect') portfolioSelect: MatSelect;
  portfolioFilters: any[] = [];
  fullPortfolioList: any[] = [];
  fullInvestorList: any [] = [];
  portfolioFiltersNames: string[] = [];
  investorFiltersNames: string[] = [];
  searchedInvestorList: any[] = [];
  searchedPortfolioList: any[] = [];
  investorsByPortfolios: any [] = [];
  portfoliosByInvestors: any [] = [];
  selectedInvestors: any [] = [];
  selectedPortfolios: any [] = [];
  investorSearch: FormControl<string> = new FormControl<string>('');
  portfolioSearch: FormControl<string> = new FormControl<string>('');
  _onDestroy = new Subject<void>();
  url = new URL(window.location.href);

  constructor(
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private loading: LoadingService,
    private formBuilder: FormBuilder,
    private dateFormat: DateFormatPipe,
    private dateCalculations: DateCalculationsService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getPortoflioAndInvestorData();
    this.searchForm = this.formBuilder.group({
      statusFilter: [],
      dateFilter: [],
      investors: [],
      portfolios: []
    })
    this.applyExistingParams();
    this.listenToSearchChanges();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    // This is called when active sort is changed or sort direction is changed
    this.sort.sortChange.subscribe((event) => {
      this.page = 0;
      this.matSortEvent = event;
      this.getPagedResponse();
    });

    // This is called when event paginator page is changed or page size is changed
    this.paginator.page.subscribe((event: PageEvent) => {
      this.page = event.pageIndex;
      this.pageSize = event.pageSize;
      this.loading.setLoading(true);
      this.getPagedResponse();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  applyExistingParams() {
    this.statusFilter = this.url.searchParams.get('statusFilter')?.split(',') || [];
    this.form.statusFilter.setValue(this.statusFilter);

    this.dateFilters = this.url.searchParams.get('dateFilter') || null;
    this.form.dateFilter.setValue(this.dateFilters);

    this.startDate = this.url.searchParams.get('startDate');
    this.endDate = this.url.searchParams.get('endDate');
    this.getAllFilters();
  }

  get form() {
    return this.searchForm.controls;
  }

  getPagedResponse() {
    const sort = this.matSortEvent?.active;
    const sortDirection = this.matSortEvent?.direction !== '' ? this.matSortEvent?.direction : null;
    const documentQuery = <DocumentQuery>{ 
      page: this.page, 
      pageSize: this.pageSize, 
      startDate: this.startDate?.slice(0, 10), 
      endDate: this.endDate?.slice(0, 10),
      sort: sort, 
      sortDesc: sortDirection && sortDirection === 'desc',
      portfolioIds: this.portfolioFilters,
      investorIds: this.investorFilters,
      status: this.statusFilter
    };

    this.api.getDocumentReports(documentQuery).subscribe((res: PagedResponse) => {
      this.documents = res;
      this.assignToDataSource(this.documents);
    }, err => {
      this.loading.setLoading(false);
    })
  }

  assignToDataSource(documents: PagedResponse) {
    this.dataSource = new MatTableDataSource(documents.items);
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.paginator.pageIndex = this.page;
      this.paginator.length = documents.totalCount;
    });
    this.dataSource.paginator = this.paginator;
    this.loading.setLoading(false);
  }

  getFullNameStatus(value) {
    let status = this.statusOptions.filter(status => status.value == value)[0];
    return status.display;
  }

  //filters methods:
  async applyFilters() {
    let statusFilterValue = this.form.statusFilter.value;
    let investorsFilterValue = this.selectedInvestors;
    let portfoliosFilterValue = this.selectedPortfolios;
    let selectedPeriod = this.form.dateFilter.value;
    const [start, end] = selectedPeriod ? this.dateCalculations.calculateDateRange(selectedPeriod, this.startDate, this.endDate) : '';
    if (start) this.startDate = this.dateFormat.transform(start, 'yyyy-MM-dd HH:mm:ss');
    if (end) this.endDate = this.dateFormat.transform(end, 'yyyy-MM-dd HH:mm:ss');

    this.loading.setLoading(true);
    if (statusFilterValue?.length > 0) {
      this.statusFilter = statusFilterValue;
    }
    this.dateFilters = selectedPeriod;
    if (investorsFilterValue?.length > 0) {
      this.investorFilters = investorsFilterValue;
    }
    if (portfoliosFilterValue?.length > 0) {
      this.portfolioFilters = portfoliosFilterValue;
    }
    this.page = 0;
    this.setFilterParams()
    this.getAllFilters();
  }

  setFilterParams() {
    let filterParams = [];
    filterParams.push(["startDate", this.startDate ? [this.startDate] : null])
    filterParams.push(["endDate", this.endDate ? [this.endDate] : null])
    filterParams.push(["statusFilter", this.statusFilter])
    filterParams.push(["dateFilter", this.dateFilters && this.dateFilters != '' ? [this.dateFilters] : null])
    filterParams.push(["investors", this.investorFilters])
    filterParams.push(["portfolios", this.portfolioFilters])
    filterParams.forEach(filter => {
      let type = filter[0];
      this.url.searchParams.delete(type);
      let values = filter[1]
      if (values?.length > 0) {
        this.url.searchParams.append(type, values.join(','));
      }
    })
    history.replaceState({}, '', this.url.href)
  }

  async clearFilters() {
    if (this.allFilters.length > 0 || this.investorFiltersNames.length > 0 || this.portfolioFiltersNames.length > 0) {
      this.searchForm.reset();
      this.statusFilter = [];
      this.investorFilters = [];
      this.portfolioFilters = [];
      this.allFilters = [];
      this.portfolioFiltersNames = [];
      this.investorFiltersNames = [];
      this.selectedInvestors = [];
      this.selectedPortfolios = [];
      this.dateFilters = '';
      this.customDateRange = '';
      this.startDate = null;
      this.endDate = null;
      this.loading.setLoading(true);
      this.page = 0;
      this.searchedInvestorList = this.fullInvestorList;
      this.searchedPortfolioList = this.fullPortfolioList;
      this.setFilterParams();
      this.getPagedResponse();
    }
  }

  getAllFilters() {
    this.allFilters = []
    if (this.statusFilter.length > 0) {
      let statusFilterByDisplay = this.statusOptions.filter((status) => this.statusFilter.includes(status.value)).map((status) => status.display);
      this.allFilters.push(...statusFilterByDisplay)
    }
    if (this.dateFilters && this.dateFilters != '') {
      if (this.dateFilters === 'Custom') {
        const splitStartDate = this.startDate.split('-')
        const splitEndDate = this.endDate.split('-')
        const startYear = splitStartDate[0]
        const startMonth = splitStartDate[1]
        const startDay = splitStartDate[2].split(' ')[0]
        const endYear = splitEndDate[0]
        const endMonth = splitEndDate[1]
        const endDay = splitEndDate[2].split(' ')[0]
        this.customDateRange = startMonth + "/" + startDay + '/' + startYear + " - " + endMonth + "/" + endDay + '/' + endYear
        this.allFilters.push(this.customDateRange)
      } else {
        this.allFilters.push(this.dateFilters)
      }
    }
    this.getInvestorFilterNames();
    this.getPortfolioFilterNames();
    this.getPagedResponse();
  }

  removeFilter(removedFilter) {
    //remove error filter
    let removedStatusFilter = this.statusOptions.filter((status) => status.display === removedFilter)[0];
    this.statusFilter = this.statusFilter.filter((status) => status != removedStatusFilter.value);
    this.searchForm.patchValue({ statusFilter: this.statusFilter });

    //remove date filter
    if (removedFilter === this.dateFilters || removedFilter === this.customDateRange) {
      this.dateFilters = ''
      this.customDateRange = ''
      this.startDate = this.endDate = null;
      this.searchForm.patchValue({ dateFilter: '' })
    }

    this.applyFilters();
  }

  //portfolio and investor filters methods:
  getPortoflioAndInvestorData() {
    const getPortfolioData = this.api.getPortfoliosMetadata();
    const getInvestorData = this.api.getInvestors();

    forkJoin([getPortfolioData, getInvestorData]).subscribe({
      next:([portfolioData, investorData]) => {
        this.fullPortfolioList = this.searchedPortfolioList = this.portfoliosByInvestors = portfolioData;
        this.fullInvestorList = this.searchedInvestorList = this.investorsByPortfolios = investorData;
        this.getCurrentPortfolioFilters();
        this.getCurrentInvestorFilters();
        //get document list
        this.getPagedResponse();
      },
      error: error => {
        console.log("Error:", error)
        //get document list anyways, without the portfolio and investors filters
        this.getPagedResponse();
      }
    });
  }

  getCurrentPortfolioFilters() {
    this.portfolioFilters = this.url.searchParams.get('portfolios')?.split(',').map(Number) || [];
    if (this.portfolioFilters) {
      this.portfolioFilters.forEach(id => {
        this.portfolioOptionClick(id)
      })
      this.form.portfolios.setValue(this.portfolioFilters);
      this.getPortfolioFilterNames();
    }
  }

  getCurrentInvestorFilters() {
    this.investorFilters = this.url.searchParams.get('investors')?.split(',').map(Number) || [];
      if (this.investorFilters) {
        this.investorFilters.forEach(id => {
          this.investorOptionClick(id)
        })
        this.form.investors.setValue(this.investorFilters);
        this.getInvestorFilterNames();
      }
  }

  getInvestorFilterNames() {
    if (this.investorFilters.length > 0) {
      this.investorFiltersNames = [];
      //get investor filter names to display as selected filters
      this.investorFiltersNames = this.fullInvestorList.filter((investor) => this.investorFilters.includes(investor.id)).map((investor) => investor.name);
    }
  }

  getPortfolioFilterNames() {
    if (this.portfolioFilters.length > 0) {
      this.portfolioFiltersNames = [];
      //get portfolio filter names to display as selected filters
      this.portfolioFiltersNames = this.fullPortfolioList.filter((portfolio) => this.portfolioFilters.includes(portfolio.id)).map((portfolio) => portfolio.name);
    }
  }

  removeOrAddIdToSelection(id: Number, arr: Array<Number>) {
    const index = arr.indexOf(id);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id)
    }
    return arr;
  }

  investorOptionClick(id: Number) {
    this.selectedInvestors = this.removeOrAddIdToSelection(id, this.selectedInvestors);
    if (this.selectedInvestors && this.selectedInvestors.length > 0) {
      this.getPortfoliosByInvestors(this.selectedInvestors.toString())
    } else {
      this.searchedPortfolioList = this.portfoliosByInvestors = this.fullPortfolioList;
    }
  }

  getPortfoliosByInvestors(investorIds) {
    let investorIdsArray = investorIds.split(",").map(Number);
    let filteredList = [];
    investorIdsArray.forEach(investorId => {
      this.fullPortfolioList.forEach(portfolio => {
        if (portfolio.relatedInvestors.includes(investorId)) {
          if (!filteredList.includes(portfolio)) {
            filteredList.push(portfolio)
          }
        }
      })
    })
    this.searchedPortfolioList = this.portfoliosByInvestors = filteredList;
  }

  portfolioOptionClick(id: Number) {
    this.selectedPortfolios = this.removeOrAddIdToSelection(id, this.selectedPortfolios);
    if (this.selectedPortfolios && this.selectedPortfolios.length > 0) {
      this.getInvestorsByPortfolios(this.selectedPortfolios.toString());
    } else {
      this.searchedInvestorList = this.investorsByPortfolios = this.fullInvestorList;
    }
  }

  getInvestorsByPortfolios(portfolioIds) {
    let portfolioIdsArray = portfolioIds.split(",").map(Number);
    let filteredList = [];
    portfolioIdsArray.forEach(portfolioId => {
      this.fullInvestorList.forEach(investor => {
        if (investor.relatedPortfolios.includes(portfolioId)) {
          if (!filteredList.includes(investor)) {
            filteredList.push(investor)
          }
        }
      })
    })
    this.searchedInvestorList = this.investorsByPortfolios = filteredList;
  }

  removeInvestorFilter(removedFilter) {
    let removedInvestorFilterId = this.fullInvestorList.filter((investor) => investor.name === removedFilter)[0].id;
    this.investorFiltersNames = this.investorFiltersNames.filter(filter => filter != removedFilter);
    this.investorFilters = this.fullInvestorList.filter((investor) => this.investorFiltersNames.includes(investor.name)).map((investor) => investor.id);
    this.searchForm.patchValue({ investors: this.investorFilters });
    this.investorOptionClick(removedInvestorFilterId);
    this.applyFilters();
  }

  removePortfolioFilter(removedFilter) {
    let removedPortfolioFilterId = this.fullPortfolioList.filter((portfolio) => portfolio.name === removedFilter)[0].id;
    this.portfolioFiltersNames = this.portfolioFiltersNames.filter(filter => filter != removedFilter);
    this.portfolioFilters = this.fullPortfolioList.filter((portfolio) => this.portfolioFiltersNames.includes(portfolio.name)).map((portfolio) => portfolio.id);
    this.searchForm.patchValue({ portfolios: this.portfolioFilters });
    this.portfolioOptionClick(removedPortfolioFilterId);
    this.applyFilters();
  }

  listenToSearchChanges() {
    // listen for search field value changes
    this.investorSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
      this.dropDownsearch(this.investorSearch.value, "investor");
      });
    this.portfolioSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
      this.dropDownsearch(this.portfolioSearch.value, "portfolio");
      });
  }

  // Dropdown Search                                                                                                                              
  dropDownsearch(query: any, type: string) {
    let result = this.selectValue(query, type);
    type == 'investor' ? this.searchedInvestorList = result : '';
    type == 'portfolio' ? this.searchedPortfolioList = result : '';
  }

  // Dropdown Select value
  selectValue(query: any, type: string): any[] {
    let result: any[] = [];
    let searchOptions: any[] = [];
    type === 'investor' ? searchOptions = this.investorsByPortfolios : '';
    type === 'portfolio' ? searchOptions = this.portfoliosByInvestors : '';
    result = searchOptions.filter(element => element.name.toLowerCase().includes(query.toLowerCase()));
    return result;
  }

  dateRangePicker() {
    this.picker.open();
    this.dateSelect.open();
  }

  // Select Date Picker
  selectDatePicker(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.startDate = dateRangeStart.value;
    this.endDate = dateRangeEnd.value;
    this.dateSelect.close();
  }
}
